<!--
 * @Author: hhy
 * @Description: 财务管理 账号管理 互联互通账户
-->
<template>
  <scroll-layout>
    <template #header>
      <ykc-search
        ref="YkcSearch"
        :data="searchData"
        :searchForm="searchParams"
        @click="handleSearchConfirm"
        @clean="handleSearchClean"></ykc-search>
    </template>
    <div class="motorcade-main-account">
      <ykc-table
        ref="YkcTable"
        title="客户列表"
        operateFixedType="right"
        :data="tableData"
        :columns="tableColumns"
        :operateButtons="tableOperateButtons.filter(item => item.enabled())">
        <template #headerButtons>
          <ykc-button
            v-for="(button, index) in pageButtons.filter(item => item.enabled())"
            :key="button.id || index"
            v-bind="button"
            @click="handlePageButtonClick(button, $event)">
            {{ button.text }}
          </ykc-button>
        </template>
        <ykc-pagination
          slot="pagination"
          ref="YkcPagination"
          direct="flex-end"
          :total="pageInfo.total"
          :page-size.sync="pageInfo.pageSize"
          :current-page.sync="pageInfo.pageIndex"
          @size-change="handlePaginationSizeChange"
          @current-change="handlePaginationCurrentChange"></ykc-pagination>
      </ykc-table>
    </div>
    <!-------------------------------------------------
    description:钱包充扣
    --------------------------------------------------->
    <inter-wallet-recharge-deduction
      :show1="show1"
      :row="currentRowWRD"
      @wallet-close="onWalletClose"
      @refresh-list="onRefreshList" />
  </scroll-layout>
</template>

<script>
  import { offlineExport, code } from '@/utils';
  import { interconnectedAccounts } from '@/service/apis';
  import YkcDialog from '@/components/ykc-ui/dialog';
  import InterWalletRechargeDeduction from '../components/InterWalletRechargeDeduction.vue';

  export default {
    name: 'InterconnectedAccountsList',
    components: {
      InterWalletRechargeDeduction,
    },
    data() {
      return {
        id: '',
        orgId: '', // 客户 ID
        orgName: '', // 客户名称
        show1: false,
        isEdit: false,
        currentRowWRD: {}, // 当前选择的行数据 钱包充扣
        ruleForm: {
          name: '',
          tips: '',
          date: '',
          orgName: '',
          orgName1: '',
          img: '',
          file: '',
          orgType: '',
          adjustType: '1',
          refund: 0,
          label: [],
          dropdown: '',
          textarea: '',
        },
        pageButtons: [
          {
            id: '2',
            enabled: () => code('finance:account:inter:export'),
            text: '导出',
            type: 'plain',
            handleClick: () => {
              this.doExportFile();
            },
          },
        ],
        tableData: [],
        tableColumns: [
          { label: '司机ID', prop: 'userId', minWidth: '100px' },
          { label: '司机账号', prop: 'userAccount', minWidth: '100px' },
          { label: '归属客户', prop: 'orgName', minWidth: '100px' },
          { label: '钱包余额', prop: 'userBalance', minWidth: '100px' },
        ],
        tableOperateButtons: [
          {
            id: '1',
            enabled: () => code('finance:account:inter:wallet'),
            text: '钱包充扣',
            handleClick: (btn, { row }) => {
              this.currentRowWRD = { ...row };
              this.show1 = true;
            },
          },
          {
            id: '3',
            enabled: () => code('finance:account:inter:detail'),
            text: '详情',
            handleClick: (btn, { row }) => {
              this.$router.push({
                path: '/financialManagement/interconnectedAccount/interconnectedAccountsDetail',
                query: { id: row.userId },
              });
            },
          },
        ],
        pageInfo: {
          pageIndex: 1,
          pageSize: 10,
        },
        searchParams: {
          // orgName: '',
          // orgId: '',
        },
      };
    },
    created() {
      const { orgName, orgId } = this.$route.query;
      this.orgName = orgName;
      this.orgId = orgId;
      this.searchTableList();
    },
    computed: {
      searchData() {
        return [
          {
            comName: 'YkcInput',
            key: 'userId',
            label: '司机ID',
            placeholder: '请输入司机ID',
          },
          {
            comName: 'YkcInput',
            key: 'userAccount',
            label: '司机账号',
            placeholder: '请输入司机账号',
          },
          {
            comName: 'YkcInput',
            key: 'orgName',
            label: '归属客户',
            placeholder: '请输入归属客户',
          },
        ];
      },
    },
    methods: {
      // 钱包充扣 关闭 事件
      onWalletClose(e, val) {
        console.log('onWalletClose:', e, val);
        this.show1 = false;
      },
      onRefreshList() {
        this.searchTableList();
      },
      beforeCancel1(done) {
        console.log('取消的回调 dialog1');
        done();
      },
      onCLose() {
        this.show1 = false;
      },
      changeStateSearch() {
        this.pageInfo.pageIndex = 1;
        this.searchTableList();
      },
      /** 根据条件查询数据 */
      searchTableList() {
        const interconnectedAccountsReqParams = {
          ...this.searchParams,
          current: this.pageInfo.pageIndex,
          size: this.pageInfo.pageSize,
        };
        interconnectedAccounts.list(interconnectedAccountsReqParams).then(response => {
          this.tableData = response?.records || [];
          this.pageInfo.total = response?.total || 0;
        });
      },
      /** 导出文件 */
      doExportFile() {
        offlineExport(
          {
            downloadType: 'finance_export',
            jsonNode: {
              downloadKey: 'interconnectionList',
              ...this.searchParams,
            },
          },
          this.pageInfo.total
        );
      },
      onAddDrawerClose(done) {
        done();
      },
      /** 点击抽屉取消按钮 callback */
      onAddDrawerCancel(drawerDone) {
        YkcDialog({
          dialogType: 'feedback',
          showTitle: false,
          showFooter: true,
          desc: '取消后操作将不被保存，确认是否取消？',
          onCancel: done => {
            done();
          },
          onConfirm: done => {
            done();
            drawerDone();
            if (this.isEdit) {
              this.isEdit = false;
            }
          },
        });
      },
      /**
       * @desc 点击页面左上角按钮 callback
       * */
      handlePageButtonClick(button, event) {
        if (typeof button.handleClick === 'function') {
          button.handleClick(button, event);
        }
      },
      /**
       * @desc 点击查询按钮
       * */
      handleSearchConfirm(form) {
        this.pageInfo.pageIndex = 1;
        Object.assign(this.searchParams, form);
        this.searchTableList();
      },
      /**
       * 点击清空查询按钮
       * */
      handleSearchClean(form) {
        Object.assign(this.searchParams, form);
      },
      /**
       * @desc 分页 change 事件监听器
       * */
      handlePaginationCurrentChange(current) {
        this.pageInfo.pageIndex = current;
        this.searchTableList();
      },
      /**
       * 分页 size  change  事件监听器
       * */
      handlePaginationSizeChange(size) {
        this.pageInfo.pageIndex = 1;
        this.pageInfo.pageSize = size;
        this.searchTableList();
      },
    },
  };
</script>
<style lang="scss" scoped>
  .drawer-button-wrap {
    padding: 0 10px;
    button {
      margin-right: 5px;
    }
  }
  .link {
    color: blue;
    cursor: pointer;
  }
  .el-radio-group .item {
    border: 0;
  }
</style>
